/* GetInTouch.css */
.get-in-touch-section {
  display: none; /* Hidden initially */
  width: 97vw; /* Full width of the viewport */
  padding: 50px 0;
  background-color: rgba(
    255,
    255,
    255,
    0.2
  ); /* White background with slight opacity */
  transition: opacity 1s ease-in-out;
  opacity: 0;
  text-align: left;
}

.get-in-touch-section.visible {
  display: block;
  opacity: 1;
}

.get-in-touch-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.get-in-touch-text {
  max-width: 600px;
}

.get-in-touch-text h2 {
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
}

.get-in-touch-text p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 10px;
}

.get-in-touch-image img {
  width: 400px;
  height: auto;
  border-radius: 15px;
}
