.landing-page {
  position: relative;
  height: 98vh;
  width: 97vw;
  padding: 2vh 0vh;
}
.landing-page-top-image {
  min-height: 97vh; /* Ensure the image container is at least 90% of viewport height */
  background: url('../../../public/images/landingPageBg.jpg') no-repeat center center/cover;
  background-size: cover;
  overflow: hidden;
  transform: scale(0.95); /* Zoom-out effect */
  animation: zoomInEffect 0.5s ease-in-out forwards;
}

@keyframes zoomInEffect {
  0% {
    transform: scale(0.95); /* Start slightly zoomed out */
  }
  100% {
    transform: scale(1); /* Zoom in to original size */
  }
}

.darken-landing-page {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Adjust the color and opacity */
  z-index: 0; /* Ensure the overlay is on top of the image */
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.content {
  max-width: 98%; /* Adjust width if needed */
  text-align: center; /* Center text inside */
  color: #fff; /* Ensure text is visible on dark background */
}

.title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  background: transparent;
  position: relative;
  animation: text 3s 1;
  letter-spacing: 8px;
}
.subtitleContainer {
  width: 60%;
  margin: 0 auto; /* Center the subtitle container */
}

.subtitle {
  font-size: 1.2rem;
  margin-bottom: 30px;
  animation: subText 3s 1;
}

@keyframes text {
  0%{
    color: black;
    margin-bottom: -40px;
  }
  30%{
    letter-spacing: 15px;
    margin-bottom: -40px;
  }
  85%{
    letter-spacing: 6px;
    margin-bottom: -40px;
  }
}

@keyframes subText {
  0%{
    opacity: 0;
  }
  30%{
    opacity: 0;
  }
  85%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@keyframes zoomOutEffect {
  0% {
    transform: scale(1.1); /* Start slightly zoomed-in */
  }
  100% {
    transform: scale(1); /* End at original size */
  }
}


.get-started-btn {
  font-size: 1.2rem;
  padding: 10px 20px;
  border-radius: 50px;
}

.stats-section {
  padding: 50px 0;
  background-color: rgba(248, 249, 250, 0.5);
  width: 97vw;
  text-align: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.stats-section.visible {
  opacity: 1;
}

.stat-item {
  margin-bottom: 20px;
}

.stat-number {
  font-size: 48px;
  font-weight: bold;
  color: #007bff; /* Bootstrap primary color */
}

.stat-item p {
  font-size: 18px;
  color: #333;
}

.footer {
  margin-top: 40px;
  font-size: 0.9rem;
}