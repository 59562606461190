/* AboutUsSection.css */
.about-us-section {
  display: none; /* Hidden initially */
  width: 97vw; /* Full width of the viewport */
  padding: 50px 0;
  background-color: rgba(102, 43, 189, 0.3);
  transition: opacity 1s ease-in-out;
  opacity: 0;
  text-align: left;
}

.about-us-section.visible {
  display: block;
  opacity: 1;
}

.about-us-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.about-us-image {
    display: flex; /* Or display: grid; */
    align-items: center;
    justify-content: center;
  }

.about-us-img {
  width: 100%; /* Or a specific width like 400px */
  flex: 1; /* Or use 'height: 100%;' if using grid */
  border-radius: 1px;
  background: url('../../../public/images/landingPageBg.jpg') no-repeat center center/cover;
  background-size: cover;
}

.about-us-text {
  max-width: 600px;
  margin-left: 20px;
}

.about-us-text p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}
