.container {
  text-align: center;
  color: white;
  margin: 0;
  position: inherit;
}
.box div{
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: 3px solid rgba(103, 85, 245, 0.8);
  z-index: 9999;
}
.box div:nth-child(even) {
  border-radius: 50%;
  border: 3px solid rgba(228, 57, 34, 0.8);
}
.box div:nth-child(1) {
  top: 12%;
  left: 42%;
  animation: animate 8s linear infinite;
}
.box div:nth-child(2) {
  top: 60%;
  left: 80%;
  animation: animate 5s linear infinite;
}
.box div:nth-child(3) {
  top: 72%;
  left: 15%;
  animation: animate 6s linear infinite;
}
.box div:nth-child(4) {
  top: 10%;
  left: 60%;
  animation: animate 4s linear infinite;
}
.box div:nth-child(5) {
  top: 67%;
  left: 40%;
  animation: animate 6s linear infinite;
}
.box div:nth-child(6) {
  top: 70%;
  left: 50%;
  animation: animate 9s linear infinite;
}
.box div:nth-child(7) {
  top: 42%;
  left: 63%;
  animation: animate 4s linear infinite;
}
.box div:nth-child(8) {
  top: 16%;
  left: 77%;
  animation: animate 7s linear infinite;
}
.box div:nth-child(9) {
  top: 80%;
  left: 56%;
  animation: animate 8s linear infinite;
}
.box div:nth-child(10) {
  top: 32%;
  left: 12%;
  animation: animate 6s linear infinite;
}
.box div:nth-child(11) {
  top: 52%;
  left: 62%;
  animation: animate 6s linear infinite;
}
.box div:nth-child(12) {
  top: 18%;
  left: 10%;
  animation: animate 6s linear infinite;
}
.box div:nth-child(13) {
  top: 78%;
  left: 78%;
  animation: animate 6s linear infinite;
}
.box div:nth-child(14) {
  top: 27%;
  left: 80%;
  animation: animate 6s linear infinite;
}
.box div:nth-child(15) {
  top: 12%;
  left: 12%;
  animation: animate 6s linear infinite;
}

@keyframes animate {
  0%{
    transform: scale(0) translateY(0) rotate(0);
    opacity: 1;
  }
  100%{
    transform: scale(1.3) translateY(-90px) rotate(360deg);
    opacity: 0;
  }
}